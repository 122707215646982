import React from 'react';
import { useSelector } from 'react-redux';


function Audio(props){
  const { current } = props; // current refers to the current assesment_test_question
  const langStore = useSelector(state => state.lang);

  return (
    <div id="play-btn-wrapper">
      { current.question.audio[langStore.current_lang] &&
        <audio
          controls
          controlsList="nodownload"
          key={`audio-${langStore.current_lang}`} // Add this key to force re-render when language changes
        >
          <source src={current.question.audio[langStore.current_lang]} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      }
    </div>
  )
}

export default Audio;