import * as actionTypes from "../actions/lang/actionTypes";
import * as translationActionTypes from "../actions/translation/actionTypes";
import * as authActionTypes from "../actions/auth/actionTypes";
import * as candidateActionTypes from "../actions/candidate/actionTypes";
import * as assesmentActionTypes from "../actions/assesment/actionTypes";

const initialState = {
  default_app_lang: process.env.REACT_APP_DEFAULT_LANG, // Should always remain the same
  current_lang: process.env.REACT_APP_DEFAULT_LANG,
  forced_lang: null, // From db assesment.evaluation_session.forced_lang === null
  candidate_pref_lang: null, // From UI language dropdown
  candidate_profile_lang: null, // From db candidates.langue
};

export const lang = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CANDIDATE_PREF_LANG_REQUEST:
      return {
        ...state,
        candidate_pref_lang: action.lang,
        current_lang: state.forced_lang ?? action.lang,
      };
    case candidateActionTypes.FETCH_CANDIDATE_SUCCESSFUL:
      return {
        ...state,
        candidate_profile_lang: action.response.lang,
        current_lang:
          state.forced_lang ??
          state.candidate_pref_lang ??
          action.response.lang ??
          state.current_lang,
      };
    case assesmentActionTypes.FETCH_ASSESMENT_SUCCESSFUL:
      return {
        ...state,
        forced_lang: action.response.evaluation_session?.forced_lang,
        current_lang:
        action.response.evaluation_session?.forced_lang ?? state.current_lang,
      };
    case translationActionTypes.SET_TRANSLATION_MISSING_REQUEST:
      return {
        ...state,
        current_lang: action.payload.lang_used ?? state.current_lang,
      };
    case translationActionTypes.RESET_TRANSLATION_MISSING_REQUEST:
      return {
        ...state,
        current_lang:
          state.forced_lang ??
          state.candidate_pref_lang ??
          state.candidate_profile_lang ??
          state.default_app_lang,
      };
    case authActionTypes.LOGOUT_REQUEST:
      return initialState;
    default:
      return state;
  }
};