import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

class LayoutProfessional extends Component {
  render() {
    const { testCompletedHandler, onPrevious } = this.props;
    return (
      <div className='page'>
        <section>
          <h1>
            <FormattedMessage id='questionnaire.completed.professional.title' />
          </h1>
          <p><FormattedMessage id='questionnaire.completed.professional.p1' /></p>
          <p>  
            <FormattedMessage id='questionnaire.completed.professional.p2'
              values={
                { 
                  SubmitForm: <strong>{this.props.intl.formatMessage({id: "questionnaire.completed.professional.p2_submit_form"})}</strong>
              }}
            />
          </p>
          <div className="button-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
            <button 
              className="btn-default" 
              onClick={onPrevious}
              style={{ marginRight: '5px' }}
            >
              <FormattedMessage id="app.global.button.previous" />
            </button>
            <button className="btn-primary" onClick={testCompletedHandler}>
            <FormattedMessage id='questionnaire.completed.professional.button.submit_questionnaire' />
            </button>
          </div>
        </section>
      </div>
    );
  }
}

export default injectIntl(LayoutProfessional);
